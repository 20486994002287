<template>
  <v-container fluid pa-1 fill-height>
    <v-row no-gutters class="fill-height">
      <v-col cols="12" sm="12" md="12" lg="12" class="fill-height">
        <v-card flat tile class="fill-height">
          <!-- <embed  id="invoiceTemplate"
            frameborder="0"
            width="100%"
            height="100%"
            marginwidth="0"
            marginheight="0" type="application/pdf" download target="_blank" src="about:blank"> -->
          <iframe
            id="invoiceTemplate"
            frameborder="0  "
            width="100%"
            height="100%"
            marginwidth="0"
            marginheight="0"
            type="application/pdf"
            download
          ></iframe>
          <table id="meter-consumption-table" style="display:none; width:300px" v-if="invoice.hasOwnProperty('meterConsumptionDetails')">
            <thead>
              <tr>
                <th>Meter #</th>
                <th>Consumption (in KW)</th>
                <th>Amount (RM)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c,index) in invoice.meterConsumptionDetails" :key="'mct'+index">
                <td>{{c.meter}}</td>
                <td>{{c.consumption}}</td>
                <td>{{c.amount}}</td>
              </tr>
            </tbody>
          </table>
          <table id="total-consumption-table" style="display:none; width:300px">
            <thead>
              <tr>
                <th>Meter</th>
                <th>Consumption</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colspan="2">Total</th>
                <td>{{invoice.hasOwnProperty("invoice") ? invoice.invoice.amount : 0}} RM</td>
              </tr>
            </tbody>
          </table>

          <table id="invoice_custom_fields" style="display:none; width:150px" v-if="invoice.hasOwnProperty('invoice')">
            <tbody>
              <tr>
                <td>Invoice Date :</td>
                <td>{{invoice.invoice.created_at.split("T")[0]}}</td>
              </tr>
              <tr>
                <td>Terms :</td>
                <td>{{invoice.invoice.bill_status.split("T")[0]}}</td>
              </tr>
              <tr>
                <td>Due Date :</td>
                <td>{{invoice.invoice.due_date.split("T")[0]}}</td>
              </tr>
              <tr v-for="field in invoice.invoice.fields" :key="'fieldVal'+field.id"  v-if="invoice.invoice.hide_custom_field == 0">
                <td>{{field.name}} :</td>
                <td>{{field.invoice_custom_field.custom_field_value || ""}}</td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  name: "standard",
  components: {},
  props: {
    invoiceId: {
      type: String
    }
  },
  data() {
    return {
      invoice:{}
    };
  },
  computed: {
    orgDetails() {
      return this.$store.getters["MDMStore/getterOrgDetails"];
    }
  },
  watch: {
    invoiceId() {
      this.generateInvoicePreview();
    }
  },
  beforeCreate() {},
  mounted() {
    this.generateInvoicePreview()
  },
  methods: {
    async generateInvoicePreview() {
      var self = this;
      var defaultOrgCustomFields = this.$store.getters["MDMStore/getterOrgCustomFields"];
      await self.$store
          .dispatch({
            type: "MDMStore/getInvoiceInfo",
            invoiceId: self.invoiceId
          })
          .then(data => {
            for(var field in defaultOrgCustomFields){
              var isFieldAdded = data.invoice.fields.findIndex(p=>p.id == defaultOrgCustomFields[field].id);
             if(isFieldAdded == -1){
               data.invoice.fields.push({id:defaultOrgCustomFields[field].id, name:defaultOrgCustomFields[field].name, invoice_custom_field:{custom_field_value:defaultOrgCustomFields[field].default}})
             }
            }
            self.invoice = Object.assign({}, data);
          });
      var pdf = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: "a4",
        putOnlyUsedFonts: true
      });
      var pageHeight =
        pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
      var pageWidth =
        pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

      let startX = 0,
        startY = 0,
        lineHeight = 14,
        imageHeight = 150,
        imageWidth = 20,
        currentX = 0,
        currentY = 0,
        marginLeft = 16,
        marginRight = 16,
        marginTop = 16,
        marginBottom = 16,
        fontStyle = "Times";
      if (this.invoice.hasOwnProperty("invoice")) {
        var org = this.$store.getters["MDMStore/getterOrgDetails"];
        var img = new Image();
        img.src = "https://mdm.tekpea.com/mdm/public/"+org.logo_file_name;
        currentX = startX + marginLeft;
        currentY = startY + marginTop + lineHeight;
        pdf.addImage(img, "JPEG", currentX, currentY, imageHeight, imageWidth);
        pdf.setFont(fontStyle);
        pdf.setFontType("bold");
        pdf.setFontSize(16);
        currentX = pageWidth - marginRight;
        pdf.text("INVOICE", currentX, currentY, { align: "right" });
        currentY = currentY + lineHeight;
        pdf.setFont(fontStyle);
        pdf.setFontType("normal");
        pdf.setFontSize(10);
        pdf.text("#" + this.invoice.invoice.invoice_no, currentX, currentY, {
          align: "right"
        });
        currentX = startX + marginLeft;
        currentY = imageHeight / 2;
       
        pdf.text(currentX, currentY, org.address.address + ",");
        currentY = currentY + lineHeight;
        pdf.text(
          currentX,
          currentY,
          org.address.city + ", " + org.address.state + ","
        );
        currentY = currentY + lineHeight;
        pdf.text(
          currentX,
          currentY,
          org.address.country + ", " + org.address.postal_code
        );
        currentY = currentY + lineHeight;
        currentY = currentY + lineHeight;
        pdf.setFontType("bold");
        pdf.text(
          currentX,
          currentY,
          this.invoice.invoice.customer.salutation +
            " " +
            this.invoice.invoice.customer.first_name +
            " " +
            this.invoice.invoice.customer.last_name
        );
        pdf.setFontType("normal");
        currentY = currentY + lineHeight;
        pdf.text(
          currentX,
          currentY,
          this.invoice.invoice.customer.billing_address.address + ","
        );
        currentY = currentY + lineHeight;
        pdf.text(
          currentX,
          currentY,
          this.invoice.invoice.customer.billing_address.city +
            ", " +
            this.invoice.invoice.customer.billing_address.state +
            ","
        );
        currentY = currentY + lineHeight;
        pdf.text(
          currentX,
          currentY,
          this.invoice.invoice.customer.billing_address.country +
            ", " +
            this.invoice.invoice.customer.billing_address.postal_code
        );
        currentY = currentY + lineHeight;
        pdf.text(
          currentX,
          currentY,
          "Period from " +
            this.invoice.invoice.period_from.split("T")[0] +
            " to " +
            this.invoice.invoice.period_to.split("T")[0]
        );
        currentY = currentY + lineHeight;
        currentX = pageWidth - marginRight;
        var customFieldData = pdf.autoTableHtmlToJson(
          document.getElementById("invoice_custom_fields")
        );

        pdf.autoTable(customFieldData.columns, customFieldData.data, {
          margin: { left: pageWidth - 180 },
          startY: currentY,
          theme: "plain",
          showHead: "never",
          styles: {
            cellPadding: 2,
            font: fontStyle
          },
          tableWidth: 150,
          columnStyles: {
            0: { halign: "right" },
            1: { halign: "right" }
          }
        });
        currentY = pdf.previousAutoTable.finalY + 3 * lineHeight;
        currentX = startX + marginLeft;
        pdf.setFont(fontStyle);
        pdf.setFontType("bold");
        pdf.setFontSize(10);
        pdf.text("Meter Consumption Details : ", currentX, currentY);
        var meterData = pdf.autoTableHtmlToJson(
          document.getElementById("meter-consumption-table")
        );
        pdf.autoTable(meterData.columns, meterData.data, {
          startY: currentY + lineHeight,
          margin: { top: 2 * lineHeight },
          styles: {
            cellPadding: 4,
            font: fontStyle
          },
          columnStyles: {
          }
        });
        currentY = pdf.previousAutoTable.finalY + lineHeight;
        var res = pdf.autoTableHtmlToJson(
          document.getElementById("total-consumption-table")
        );

        pdf.autoTable(res.columns, res.data, {
          margin: { top: 2 * lineHeight },
          showHead: "never",
          styles: {
            cellPadding: 4,
            font: fontStyle
          },
          columnStyles: {
            0: { halign: "right" , fontStyle:'bold'},
            2: { halign: "center", fontStyle:'bold' }
          }
        });
        currentX = startX + marginLeft;
        currentY =  pdf.previousAutoTable.finalY  +3* lineHeight;
        pdf.setFontType("normal");
        pdf.setFontSize(12);
        pdf.text( currentX,
          currentY,"Thank you for your business"
          )
      }
      document.getElementById("invoiceTemplate").setAttribute(
        "src",
        pdf.output("datauristring")
        //+ '#toolbar=0'
      );
    }
  }
};
</script>
  